import { fetchAuthSession } from "aws-amplify/auth";
import { API_SERVER } from "../config/constants";

export const createKnowledgeBase = async (payload) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/knowledge-base`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(payload),
      }
    );

    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.message || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to createKnowledgeBase:", error);
    throw error;
  }
};

export const fetchKnowledgeBases = async (orgSkuuid) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${orgSkuuid}/knowledge-bases`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to fetchKnowledgeBases:", error);
    throw error;
  }
};

export const updateKnowledgeBase = async (orgSkuuid, knowledgeBaseId, payload) => {
  try {
    const session = await fetchAuthSession();

    const response = await fetch(
      `${API_SERVER}/organization/${orgSkuuid}/knowledge-bases/${knowledgeBaseId}`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${session.tokens.idToken.toString()}`,
        },
        body: JSON.stringify(payload),
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to update Knowledge base:", error);
    throw error;
  }
};

export const deleteKnowledgeBase = async (orgSkuuid, knowledgeBaseId) => {
  try {
    const session = await fetchAuthSession();
    const response = await fetch(
      `${API_SERVER}/organization/${orgSkuuid}/knowledge-bases/${knowledgeBaseId}`,
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.tokens.idToken.toString()}`,
        },
      }
    );
    const responseData = await response.json();
    if (!response.ok) throw new Error(responseData.error || "Network response was not ok");
    return responseData;
  } catch (error) {
    console.error("Failed to delete knowledge base:", error);
    throw error;
  }
};

