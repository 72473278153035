import React, { useEffect, useRef, useState } from "react";
import { Modal } from "flowbite-react";
import StatusType from "../../../data/enums/statusType";
import ActionButtonsGroup from "../../common/buttons/ActionButtonsGrup";
import { formatDateWithOrdinal, truncateContent } from "../../../utils/common";
import { socialPlatformIconsMap } from "../../../utils/socialPlatformUtils";
import AddSocialAccountButton from "../../Campaigns/AddSocialAccountButton";
import StatusBadge from "../../common/StautsBagde";

export default function PublishModal({
  openModal,
  setOpenModal,
  organizationSocialAccounts,
  organizationskuuid,
  postTargetPlatforms,
  handlePublish,
  handleSchedule,
  selectedSocialAccounts,
  setSelectedSocialAccounts,
}) {
  const ref = useRef();
  const handleClose = () => {
    setOpenModal(false);
    setSelectedSocialAccounts([]);
  };

  const [isAllDisabled, setIsAllDisabled] = useState(false);
  const [publishDisabled, setPublishDisabled] = useState(false);
  const [scheduleDisabled, setScheduleDisabled] = useState(false);

  useEffect(() => {
    const lengthProjectSocialAccounts = organizationSocialAccounts.length;
    let count = 0;
    let targetPlatformsPublished = [];

    postTargetPlatforms.forEach((targetPlatform) => {
      if (targetPlatform.status === StatusType.PUBLISHED) {
        targetPlatformsPublished.push(targetPlatform.social_account_id);
      }
      let setTargetPlatformsPublished = new Set(targetPlatformsPublished);
      count = setTargetPlatformsPublished.size;
    });
    if (count === lengthProjectSocialAccounts) return setIsAllDisabled(true);
    else setIsAllDisabled(false);
	}, [organizationSocialAccounts, postTargetPlatforms, selectedSocialAccounts]);

  const handleAccountSelect = (event, accountSelected, selectAll) => {
    if (selectAll) {
      const isChecked = event.target.checked;
      if (isChecked) {
        const socialAccounts = organizationSocialAccounts
        .map((socialAccount) => {
          const targetPlatform = targetPlatformOfSocialAccount(socialAccount.social_account_id);
          if (
            targetPlatform &&
            targetPlatform.status === StatusType.PUBLISHED
          ) {
            return null;
          } else {
            return {
              ...socialAccount,
              target_platform_sk: targetPlatform?.sk,
            }
          }
        })
        .filter((item) => item !== null);
        setSelectedSocialAccounts(socialAccounts);
      } else {
        setSelectedSocialAccounts([]);
      }
    } else {
      ref.current.checked = false;
      setSelectedSocialAccounts((prevSocialAccountsSelected) => {
        const foundTargetPlatform = prevSocialAccountsSelected.find(
          (currentSocialAccount) =>
            currentSocialAccount.social_account_id === accountSelected.social_account_id
        );

        return foundTargetPlatform
          ? prevSocialAccountsSelected.filter(
              (socialAccount) => socialAccount.social_account_id !== accountSelected.social_account_id
            )
          : [...prevSocialAccountsSelected, accountSelected];
      });
    }
  };

  const targetPlatformOfSocialAccount = (accountId) => {
    const targetPlatform = postTargetPlatforms.find(
      (platform) => platform.social_account_id === accountId
    );
    if (targetPlatform) return targetPlatform;
    return false;
  };

  const targetDataMap = {
    [StatusType.PUBLISHED]: (target) => ({
      bgColor: "bg-main-blue",
      date: target.published_at,
      statusReadable: StatusType.toReadable(StatusType.PUBLISHED),
      textColor: "text-white",
    }),
    [StatusType.SCHEDULED]: (target) => ({
      bgColor: "bg-[#F3F3F4]",
      date: target.schedule_time,
      statusReadable: StatusType.toReadable(StatusType.SCHEDULED),
      textColor: "text-[#000000]",
    }),
    [StatusType.FAILED]: (target) => ({
      bgColor: "bg-red-500",
      date: target.created_at,
      statusReadable: StatusType.toReadable(StatusType.FAILED),
      textColor: "text-white",
    }),
  };

  const isSocialAccountSelected = (accountId) =>
    selectedSocialAccounts.find(
      (socialAccount) => socialAccount.social_account_id === accountId
    );

  const handlePublishCallback = () => {
    handlePublish();
    setSelectedSocialAccounts([]);
  };

  useEffect(() => {
    const areSelected = selectedSocialAccounts.length === 0;

    if (areSelected || isAllDisabled) {
      setPublishDisabled(true);
      setScheduleDisabled(true);
    } else {
      setScheduleDisabled(false);
      setPublishDisabled(false);
    }
  }, [selectedSocialAccounts]);

  return (
    <Modal show={openModal} size="2xl" onClose={handleClose}>
      <Modal.Header className="text-center">
        Select Target Platforms
      </Modal.Header>
      <Modal.Body className="p-3 py-6">
        <div className="pl-9 mb-2 flex justify-between items-center flex-direction-row">
          <div>
            <input
              ref={ref}
              disabled={isAllDisabled}
              id="selectAll"
              type="checkbox"
              onChange={(e) => handleAccountSelect(e, null, true)}
              className="w-4 h-4 mr-2 text-main-blue bg-gray-100 border-gray-300 rounded focus:ring-main-blue dark:focus:ring-main-blue dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="selectAll" className="text-[14px] font-medium ml-1">
              Select all
            </label>
          </div>
        </div>
        <div className="space-y-4 overflow-y-auto min-h-[120px] max-h-[200px] pr-2">
          <div>
            {organizationSocialAccounts.map((account) => {
              const social_account_id = account.social_account_id;
              const Icon =
                socialPlatformIconsMap[social_account_id.split("#")[1]];
              const targetPlatform = targetPlatformOfSocialAccount(social_account_id);
              const isScheduled =
                targetPlatform &&
                targetPlatform.status === StatusType.SCHEDULED;
              const isPublished =
                targetPlatform &&
                targetPlatform.status === StatusType.PUBLISHED;
              const data =
                targetPlatform &&
                targetDataMap[targetPlatform.status](targetPlatform);

              const isSelected = isSocialAccountSelected(social_account_id);

              return (
                <div key={social_account_id} className="pl-9 py-2">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center me-4">
                      <input
                        id={social_account_id}
                        type="checkbox"
                        value={account.account_name}
                        onChange={() => {
                          const account_with_target_sk = {
                            ...account,
                            target_platform_sk: targetPlatform?.sk
                          };
                          handleAccountSelect(null, account_with_target_sk, false);
                        }}
                        checked={isSelected}
                        disabled={!isPublished ? false : !!targetPlatform}
                        className="w-4 h-4 mr-2 text-main-blue bg-gray-100 border-gray-300 rounded focus:ring-main-blue dark:focus:ring-main-blue dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <Icon className="h-5 w-5 m-2 text-gray-700" />
                      <label
                        htmlFor={social_account_id}
                        className="text-[14px] font-medium ml-1"
                      >
                        <div
                          className={`line-clamp-1 font-semibold ${
                            targetPlatform && !isScheduled && "text-gray-500"
                          }`}
                        >
                          {
                            truncateContent(account.account_name, 45)
                              .truncatedText
                          }
                          <StatusBadge status={targetPlatform ? targetPlatform.status : StatusType.READY} />
                        </div>
                        {targetPlatform && (
                          <div className="text-[12px] text-gray-500">
                            {formatDateWithOrdinal(data.date) ?? "-"}
                          </div>
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              );
            })}
            <br />
          </div>
          {organizationSocialAccounts.length === 0 && (
            <>
              <AddSocialAccountButton organizationskuuid={organizationskuuid}/>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Publish Now"
            cancelLabel="Schedule"
            onSave={handlePublishCallback}
            onCancel={handleSchedule}
            saveDisabled={publishDisabled}
            cancelDisabled={scheduleDisabled}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}
