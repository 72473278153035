import { useQuery } from "@tanstack/react-query";
import { PromptTemplateContext } from "./PromptTemplateContext";
import { fetchPromptTemplates, fetchSystemPromptTemplates } from "../../services";
import { useState } from "react";
import * as NotificationService from "../../utils/notificationService";


function PromptTempateProvider({ children }) {
  const [orgSkuuid, setOrgSkuuid] = useState(null);

  const { data: systemTemplates, isLoading: isSystemTemplateLoading } = useQuery({
    queryKey: ["systemTemplates", orgSkuuid],
    queryFn: async () => await fetchSystemPromptTemplates(orgSkuuid),
    enabled: !!orgSkuuid,
    onError: (error) => {
      NotificationService.notifyError(
        `Failed to fetch system prompt templates: ${error.message}`
      );
    },
  });

  const { data: promptTemplates, isLoading: isPromptTemplateLoading } = useQuery({
    queryKey: ["promptTemplates", orgSkuuid],
    queryFn: async () => await fetchPromptTemplates(orgSkuuid),
    enabled: !!orgSkuuid,
    onError: (error) => {
      NotificationService.notifyError(
        `Failed to fetch user prompt templates: ${error.message}`
      );
    },
  });

  const contextValue = {
    setOrgSkuuid,
    promptTemplates,
    systemTemplates,
    isPromptTemplateLoading,
    isSystemTemplateLoading
  };

  return (
    <PromptTemplateContext.Provider value={contextValue}>
      {children}
    </PromptTemplateContext.Provider>
  );
}

export default PromptTempateProvider;
