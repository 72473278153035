import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { Modal } from "flowbite-react";

import * as NotificationService from "../../utils/notificationService";
import { validateInput } from "../../utils/validations";
import { createProject } from "../../services";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";


const ProjectModal = ({ organizationskuuid, showModal, closeModal }) => {
  const { orgId } = useOutletContext();
  const queryClient = useQueryClient();
  const [projectTitle, setProjectTitle] = useState("");
  const initialErrors = { title: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(false);

  useEffect(() => {
    setIsCreateButtonDisabled(!projectTitle.trim());
  }, [projectTitle]);

  const handleSave = async () => {
    try {
      const payload = {
        title: projectTitle,
        organization_id: orgId
      }
      const createdProject = await createProject(payload);
      NotificationService.notifySuccess("Project created successfully");
      queryClient.setQueryData(["myProjects", organizationskuuid], (oldData) => {
        return oldData ? [...oldData, createdProject] : [createdProject];
      });
      closeModal();
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(errorMessage);
    }
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleCancel = () => {
    closeModal();
    setProjectTitle("");
    setErrors(initialErrors);
  };

  const validateProjectTitle = () => {
    validateInput(
      projectTitle,
      "title",
      setErrors,
      "Project title is required"
    );
  };

  const validateAllInputs = () => {
    validateProjectTitle();
  };

  return (
    <Modal
      show={showModal}
      size="lg"
      onClose={handleCancel}
    >
      <Modal.Header className="text-center">New Project</Modal.Header>
      <Modal.Body className="p-3 py-6">
        <div className="space-y-6">
          <div className="relative">
            <label className="block text-black">Project title *</label>
            <textarea
              className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
              placeholder="Project title*"
              value={projectTitle}
              onBlur={validateProjectTitle}
              onChange={handleInputChange(
                setProjectTitle,
                "title",
                "Project title is required"
              )}
            />
            <InputErrorDisplayer message={errors.title} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Create"
            saveDisabled={isCreateButtonDisabled}
            onCancel={handleCancel}
            onSave={handleSave}
            onSaveDisabled={validateAllInputs}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectModal;
