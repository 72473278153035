export const formatCredits = (credits) => {
  if (credits >= 1000000) {
    return `${(credits / 1000000).toFixed(1)} Mill`;
  } else if (credits >= 1000) {
    return `${(credits / 1000).toFixed(1)} K`;
  } else {
    return credits.toString();
  }
};

export const getTotalCredits = (orgData) => {
  if (!orgData) return "0";
  return formatCredits(orgData.paid_credits + orgData.free_credits);
};
