import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getMyRole } from "../services";
import RoleType from "../data/enums/roleType";

export default function SettingLayout() {
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationskuuid: orgSkuuid } = useParams();
  const orgData = useOutletContext();

  const { data: myOrgInfo } = useQuery({
    queryKey: ["MyRole", orgSkuuid],
    queryFn: () => getMyRole(orgSkuuid),
    staleTime: 5 * 60 * 1000,
  });

  const navItems = [
    {
      label: "General settings",
      route: `/mkt/organizations/${orgSkuuid}/settings/general-settings`,
      isActive: location.pathname.includes("settings/general-settings"),
      allowedRoles: [RoleType.OWNER, RoleType.ADMIN],
    },
    {
      label: "Billing",
      route: `/mkt/organizations/${orgSkuuid}/settings/billing`,
      isActive: location.pathname.includes("settings/billing"),
      allowedRoles: [RoleType.OWNER, RoleType.ADMIN],
    },
    {
      label: "User management",
      route: `/mkt/organizations/${orgSkuuid}/settings/user-management`,
      isActive: location.pathname.includes("settings/user-management"),
      allowedRoles: [RoleType.OWNER, RoleType.ADMIN, RoleType.MEMBER],
    },
  ];

  return (
    <>
      <div className="flex relative gap-6 mt-4 border-b border-solid text-base font-semibold tracking-tight text-center">
        {navItems.map(({ label, route, isActive, allowedRoles }) => {
          if (!allowedRoles.includes(myOrgInfo?.role)) return null;
          return (
            <div
              key={label}
              className={`z-0 cursor-pointer ${
                isActive
                  ? "text-main-blue border-b-[3px] border-main-blue"
                  : "text-[gray]"
              }`}
              onClick={() => navigate(route)}
            >
              {label}
            </div>
          );
        })}
      </div>
      <br />
      <Outlet context={orgData}/>
    </>
  );
}
