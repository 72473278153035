import React, { useEffect, useState } from "react";

import { CLOSE_ICON_URL } from '../../config/ExternalResources';
import { updatePromptTemplate } from '../../services';
import Button from '../common/Button';
import { validateInput } from '../../utils/validations';
import * as NotificationService from "../../utils/notificationService";
import { READABLE_SITE_NAME } from '../../config/constants';
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";


const EditTemplate = ({
  name,
  queryClient,
  organizationskuuid,
  description,
  templateSk,
  closeModal,
  reference,
}) => {
  const [promptName, setPromptName] = useState(name || "");
  const [promptDescription, setPromptDescription] = useState(description || "");

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  const [errors, setErrors] = useState({ name: "", description: "" });

  useEffect(() => {
    const isSameData = promptName === name && promptDescription === description;
    const isDataEmpty = !promptName.trim() || !promptDescription.trim();
    setIsSaveButtonDisabled(isDataEmpty || isSameData);
  }, [promptName, promptDescription]);

  const handleSave = async () => {
    try {
      const promptSkuuid = templateSk.split("#")[1];
      const updatedPromptTemplate = await updatePromptTemplate(
        organizationskuuid,
        promptSkuuid,
        promptName,
        promptDescription,
        reference
      );
      queryClient.setQueryData(
        ["promptTemplates", organizationskuuid],
        (oldData) => {
          return oldData.map((template) =>
            template.sk === templateSk
              ? { ...template, ...updatedPromptTemplate }
              : template
          );
        }
      );
      NotificationService.notifySuccess("Prompt template updated successfully");
      closeModal();
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to update prompt template: ${errorMessage}`
      );
    }
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white rounded-2xl shadow-lg w-full max-w-[45%] p-6">
        <div className="text-center text-2xl font-bold mb-4">
          Edit Prompt Template
        </div>
        <img
          loading="lazy"
          onClick={closeModal}
          src={CLOSE_ICON_URL}
          className="object-contain w-6 h-6 absolute top-3 right-3 cursor-pointer"
          alt="Close modal"
        />
        <div className="space-y-6">
          <div>
            <label className="block text-black">Prompt Name *</label>
            <textarea
              className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
              placeholder="Give your prompt a name that you can easily recognize"
              value={promptName}
              onBlur={() =>
                validateInput(
                  promptName,
                  "name",
                  setErrors,
                  "Prompt template name is required"
                )
              }
              onChange={handleInputChange(
                setPromptName,
                "name",
                "Prompt template name is required"
              )}
            />
            <InputErrorDisplayer message={errors.name} />
          </div>
          <div>
            <label className="block text-black">Prompt Description *</label>
            <textarea
              className="mt-2 w-full bg-white border border-gray-300 rounded-lg p-2"
              rows="6"
              value={promptDescription}
              onBlur={() =>
                validateInput(
                  promptDescription,
                  "description",
                  setErrors,
                  "Prompt template description is required"
                )
              }
              onChange={handleInputChange(
                setPromptDescription,
                "description",
                "Prompt template description is required"
              )}
              placeholder={`Write your prompt details as the question you would like to ask ${READABLE_SITE_NAME}’s AI in order to get a fine-tuned response.`}
            />
            <InputErrorDisplayer message={errors.description} />
          </div>
        </div>
        {reference && (
          <div className="pt-2">
            <p className="text-base tracking-wide leading-none text-zinc-600">
              Source:{" "}
              <span className="text-[#1DA1F2] break-words">{reference}</span>
            </p>
          </div>
        )}
        <div className="flex flex-col space-y-4 mt-4 max-w-[208px] mx-auto">
          <Button disabled={isSaveButtonDisabled} onClick={handleSave}>
            Save
          </Button>
          <Button outline onClick={closeModal}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditTemplate;
