import { useState } from "react";
import { FaTrashAlt, FaCopy } from "react-icons/fa";

import {
  socialPlatformDisconnectCallbacksMap,
  getSocialPlatformIcon,
  getSocialPlatformName,
} from "../../utils/socialPlatformUtils";

import Button from "../common/Button";
import AddSocialAccountModal from "./AddSocialAccountModal";
import { Tooltip } from "./Tooltip";
import DeleteConfirmationModal from "../common/modals/DeleteConfirmationModal";

export default function SocialAccountsTable({
  apps,
  organizationskuuid,
  isLoading,
  setIsLoading,
  fetchApps,
}) {
  const [currentTooltip, setCurrentTooltip] = useState(null);
  const [currentApp, setCurrentApp] = useState(null);
  const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
  const [isTooltipVisible, setIsTooltipVisible] = useState({
    copy_message: false,
    disconnect_message: false,
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);

  const toggleCurrentTooltipHandler = (id) => {
    setCurrentTooltip(currentTooltip === id ? null : id);
  };

  const handleMouseEnter = (messageType, id) => {
    setIsTooltipVisible((prev) => ({ ...prev, [messageType]: true }));
    toggleCurrentTooltipHandler(id);
  };

  const handleMouseLeave = (messageType) => {
    setIsTooltipVisible((prev) => ({ ...prev, [messageType]: false }));
    toggleCurrentTooltipHandler(null);
  };

  const handleCopy = (copyText) => {
    navigator.clipboard.writeText(copyText);
  };

  const handleDisconnectApp = (app) => {
    socialPlatformDisconnectCallbacksMap[
      getSocialPlatformName(app.social_account_id)
    ]?.(organizationskuuid, app.social_account_id, setIsLoading, fetchApps);
  };

  return (
    <div className="w-full bg-white">
      <table className="min-w-full bg-white border-separate border-spacing-y-2">
        <thead>
          <tr className="bg-[#F1F6F8]">
            <th className="px-4 text-left text-gray-600">Target platform</th>
            <th className="px-4 text-left text-gray-600">Name</th>
            <th className="px-4 text-left text-gray-600">Status</th>
            <th className="px-4 text-left text-gray-600">Actions</th>
            <th className="text-right">
              <Button
                className="bg-blue-500 text-white py-2 px-4 w-36"
                style={{ borderRadius: "4px" }}
                onClick={() => setOpenAddAccountModal(true)}
              >
                Add Account
              </Button>
              <AddSocialAccountModal
                openModal={openAddAccountModal}
                setOpenModal={setOpenAddAccountModal}
              />
            </th>
          </tr>
        </thead>

        {isLoading && apps.length === 0 ? (
          <tbody>
            <tr>
              <td colSpan={5} className="text-center py-4">
                <div className="text-gray-600">Loading...</div>
              </td>
            </tr>
          </tbody>
        ) : apps.length === 0 && !isLoading ? (
          <tbody>
            <tr>
              <td colSpan={5} className="text-center py-4">
                <div className="text-gray-600">No social accounts found</div>
              </td>
            </tr>
          </tbody>
        ) : (
          <tbody>
            {apps.map((app, index) => (
              <tr key={index} className="shadow">
                <td className="py-4 px-4 flex items-center space-x-2">
                  {getSocialPlatformIcon(app.social_account_id)}
                  <span className="font-gilroy-bold text-[18px] font-normal leading-6 tracking-[0.15px] text-left">
                    {getSocialPlatformName(app.social_account_id)}
                  </span>
                </td>
                <td className="py-4 px-4">
                  <span>{app.account_name ?? "Not provided"}</span>
                </td>
                <td className="py-4 px-4">
                  <span className="text-green-700 px-4 py-2 rounded bg-[#CFFFD2]">
                    Active
                  </span>
                </td>
                <td className="py-4 px-4  flex items-center gap-4">
                  <div className="flex items-center space-x-2 relative">
                    <FaTrashAlt
                      onMouseEnter={() =>
                        handleMouseEnter(
                          "disconnect_message",
                          app.social_account_id
                        )
                      }
                      onMouseLeave={() =>
                        handleMouseLeave("disconnect_message")
                      }
                      onClick={() => {
                        setCurrentApp(app);
                        setIsConfirmModalOpen(true);
                      }}
                    />
                    {isTooltipVisible["disconnect_message"] &&
                      currentTooltip === app.social_account_id && (
                        <Tooltip message="Disconnect" />
                      )}
                  </div>
                  <div className="flex items-center space-x-2 relative">
                    <FaCopy
                      onMouseEnter={() =>
                        handleMouseEnter("copy_message", app.social_account_id)
                      }
                      onMouseLeave={() => handleMouseLeave("copy_message")}
                      onClick={() => handleCopy(app.social_account_id)}
                    />
                    {isTooltipVisible["copy_message"] &&
                      currentTooltip === app.social_account_id && (
                        <Tooltip message="Copy ID" />
                      )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        )}
      </table>
      <DeleteConfirmationModal
        showModal={isConfirmModalOpen}
        setOpenModal={setIsConfirmModalOpen}
        confirmDelete={() => handleDisconnectApp(currentApp)}
        entityName="Social Account"
      />
    </div>
  );
}
