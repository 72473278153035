import { useContext } from "react";
import { PromptTemplateContext } from "../../context/PromptTemplate/PromptTemplateContext";

const usePromptTemplate = () => {
  const context = useContext(PromptTemplateContext);
  if (!context) {
    throw new Error("usePromptTemplate must be used within a PromptTemplateProvider");
  }

  return context;
};

export default usePromptTemplate;
