import { Modal } from "flowbite-react";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import CollaboratorsForm from "../collaborators/CollaboratorsForm";

export default function AddCollaboratorModal({
  openModal,
  handleCancel,
  collaborators,
  setCollaborators,
  createCollaboratorHandler,
  setIsValidCollaboratorsData,
  isValidCollaboratorsData,
}) {
  return (
    <Modal
      show={openModal}
      size="xl"
      onClose={handleCancel}
      className="rounded-2xl"
    >
      <Modal.Header className="text-center text-lg font-semibold">
        Add collaborator to the project
      </Modal.Header>
      <Modal.Body className="p-3">
        <div className="space-y-4 h-[240px]">
          <CollaboratorsForm
            titleIsVisible={false}
            collaborators={collaborators}
            setCollaborators={setCollaborators}
            setIsValidCollaboratorsData={setIsValidCollaboratorsData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer className="flex justify-end space-x-3">
        <ActionButtonsGroup
          saveLabel="Create"
          saveDisabled={!isValidCollaboratorsData || collaborators.length === 0}
          onSave={createCollaboratorHandler}
          onCancel={handleCancel}
          onSaveDisabled={false}
        />
      </Modal.Footer>
    </Modal>
  );
}
