import React from "react";

const PopUpMenuItems = ({ items }) => {
    const labelStyles = {
        edit: "text-gray-700 hover:bg-gray-200",
        duplicate: "text-blue-600 hover:bg-blue-100",
        delete: "text-red-600 hover:bg-red-100",
    };

    const handleClick = (event, item) => {
        event.stopPropagation();
        item.onClick && item.onClick();
    }

    return (
        <div className="absolute top-12 right-3 bg-white shadow-lg rounded-md p-2 w-32">
            <ul className="space-y-1">
                {items.map((item, index) => {
                    const style = labelStyles[item.label?.trim().toLowerCase()] || labelStyles.edit;
                    return item.isActive && (
                        <li key={index}>
                            <button
                                onClick={(event) => { handleClick(event, item); }}
                                className={`w-full text-left px-2 py-1 rounded-md ${style}`}
                            >
                                {item.label}
                            </button>
                        </li>
                    )
                })}
            </ul>
        </div>
    );
};

export default PopUpMenuItems;
