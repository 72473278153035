import React from "react";
import { ReactComponent as ThreeDot } from "./../../assets/threedot.svg";
import PopUpMenuItems from '../common/PopUpMenuItems';
import { truncateContent } from "../../utils/common";

const PromptTemplateCard = ({
    template,
    selectedPromptId,
    onClickOptions,
    onClickEditOption,
    onClickDuplicateOption,
    onClickDeleteOption,
    isSystem,
}) => {
    const { truncatedText, isTruncated } = truncateContent(template.prompt_template_content);

    const menuItems = [
      {
        label: "Edit",
        onClick: () => onClickEditOption(template),
        isActive: !isSystem,
      },
      {
        label: "Duplicate",
        onClick: () => onClickDuplicateOption(template),
        isActive: true,
      },
      {
        label: "Delete",
        onClick: () => onClickDeleteOption(template),
        isActive: !isSystem,
      },
    ];


    return (
        <div
            key={template.id}
            className="relative flex flex-col justify-center bg-white rounded min-h-[220px] shadow-lg w-full p-4"
        >
            <div 
                className="text-lg text-center font-bold text-[#1DA1F2] overflow-hidden truncate"
                title={template.title}
            >
                {template.title}
            </div>
            <div 
                className="mt-2 text-center text-base text-gray-700 break-words"
                title={isTruncated ? template.prompt_template_content : null}
            >
                {truncatedText}
            </div>
            <div
                className="absolute top-3 right-3 cursor-pointer"
                onClick={() => onClickOptions(template.id)}
            >
                <ThreeDot />
            </div>
            {selectedPromptId === template.id && (
                <PopUpMenuItems
                    items={menuItems}
                />
            )}
        </div>
    );
};

export default PromptTemplateCard;
