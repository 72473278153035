import { useState, useRef, useEffect, useMemo } from "react";
import { isInputValid } from "../../utils/validations";
import Button from "../common/Button";
import { getOrganizationById, updateOrganization } from "../../services";
import { useQueryClient, useMutation, useQuery } from "@tanstack/react-query";
import { useOutletContext, useParams } from "react-router-dom";
import * as NotificationService from "../../utils/notificationService";

export default function GeneralOrgSettings() {
  const [orgName, setOrgName] = useState("");
  const [status, setStatus] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [error, setError] = useState("");
  const [isChanged, setIsChanged] = useState(false);
  const dropdownRef = useRef(null);
  const QueryClient = useQueryClient();
  const { organizationskuuid: orgSkuuid } = useParams();
  const { orgId } = useOutletContext();

  const { data: orgData } = useQuery({
    queryKey: ["organization", orgSkuuid],
    queryFn: () => getOrganizationById(orgId),
    enabled: !!orgSkuuid,
  });

  const mutation = useMutation({
    mutationFn: async (updatedData) =>
      await updateOrganization(orgId, updatedData),
    onSuccess: () => {
      QueryClient.invalidateQueries(["organization", orgSkuuid]);
      setIsChanged(false);
      NotificationService.notifySuccess("Organization updated successfully.");
    },
    onError: () => {
      setError("Failed to update the organization. Please try again.");
      NotificationService.notifyError("Failed to update the organization.");
    },
  });

  useEffect(() => {
    if (orgData) {
      setOrgName(orgData.name || "");
      setStatus(orgData.status || "Active");
    }
  }, [orgData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isInputValid(orgName)) {
      mutation.mutate({ name: orgName, status });

      setIsChanged(false);
    } else {
      setError(
        "Invalid organization name. Please avoid special characters and too short names."
      );

      setTimeout(() => {
        setError("");
      }, 4000);
    }
  };

  const statusColors = useMemo(
    () => ({
      Active: "bg-green-500",
      Archived: "bg-gray-500",
    }),
    []
  );

  const getStatusDot = (statusType) => {
    return statusColors[statusType] || "bg-green-500";
  };

  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value);
    setIsChanged(true);
  };

  const handleStatusChange = (statusOption) => {
    setStatus(statusOption);
    setIsDropdownOpen(false);
    setIsChanged(true);
  };

  return (
    <div className="w-auto max-w-2xl mx-4 bg-white rounded-lg shadow-sm p-6">
      <form className="space-y-6">
        <div className="space-y-2">
          <h1 className="text-2xl font-bold">
            Manage your organization&apos;s basic information
          </h1>
        </div>

        <div className="space-y-4">
          <div className="space-y-2">
            <label
              htmlFor="orgName"
              className="block text-sm font-medium text-gray-700"
            >
              Organization Name
            </label>
            <input
              type="text"
              id="orgName"
              value={orgName}
              onChange={handleOrgNameChange}
              className={`w-full px-3 py-2 border ${
                error ? "border-red-500" : "border-gray-300"
              } rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500`}
              placeholder="Enter organization name"
            />
            {error && <p className="text-red-500 text-sm">{error}</p>}
          </div>

          <div className="space-y-2">
            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700"
            >
              Organization Status
            </label>
            <div className="relative" ref={dropdownRef}>
              <button
                type="button"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                className="w-full px-3 py-2 text-left border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
              >
                <div className="flex items-center gap-2">
                  <div
                    className={`w-2 h-2 rounded-full ${getStatusDot(status)}`}
                  />
                  {status.charAt(0).toUpperCase() + status.slice(1)}
                </div>
              </button>

              {isDropdownOpen && (
                <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  {["Active", "Archived"].map((statusOption) => (
                    <button
                      key={statusOption}
                      type="button"
                      className="w-full px-3 py-2 text-left hover:bg-gray-100 flex items-center gap-2"
                      onClick={() => handleStatusChange(statusOption)}
                    >
                      <div
                        className={`w-2 h-2 rounded-full ${getStatusDot(
                          statusOption
                        )}`}
                      />
                      {statusOption.charAt(0).toUpperCase() +
                        statusOption.slice(1)}
                    </button>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>

        <Button
          type="submit"
          disabled={!isChanged}
          solid
          className="w-full sm:w-auto"
          onClick={handleSubmit}
        >
          Save Changes
        </Button>
      </form>
    </div>
  );
}
