import React, { useContext, useEffect, useState } from "react";
import { Modal } from "flowbite-react";
import { useQueryClient } from "@tanstack/react-query";

import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import { initalOrganization } from "../../config/constants";
import { isInputValid, validateInput } from "../../utils/validations";
import * as NotificationService from "../../utils/notificationService";
import { createOrganization, createRole } from "../../services";
import CollaboratorsForm from "../collaborators/CollaboratorsForm";
import CustomInput from "../common/forms/CustomInput";
import { AuthContext } from "../../AuthProvider";

export default function CreateOrganizationModal({ openModal, setOpenModal }) {
  const queryClient = useQueryClient();
  const initialErrors = { name: "" };
  const [errors, setErrors] = useState(initialErrors);
  const [isValidCollaboratorsData, setIsValidCollaboratorsData] =
    useState(true);
  const [newOrganization, setNewOrganization] = useState(initalOrganization);
  const [collaborators, setCollaborators] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const auth = useContext(AuthContext);

  const handleInputChange = (field, errorMessage) => (e) => {
    const value = e.target.value;
    setNewOrganization((prevState) => ({ ...prevState, [field]: value }));
    validateInput(value, field, setErrors, errorMessage);
  };

  const canSaveData = () => {
    const isNameValid = isInputValid(newOrganization.name);
    return isNameValid && isValidCollaboratorsData;
  };

  const validateName = () => {
    validateInput(
      newOrganization.name,
      "name",
      setErrors,
      "Invalid organization name. Please avoid special characters and too short names."
    );
  };

  const validateAllInputs = () => {
    validateName();
  };

  const createNewOrganization = async () => {
    try {
      const organizationCreated = await createOrganization({
        ...newOrganization,
        owner: `${auth.user.given_name} ${auth.user.family_name}`,
      });

      NotificationService.notifySuccess("Organization created successfully");
      if (collaborators.length !== 0) {
        await createCollaborators(organizationCreated.id);
      }

      queryClient.setQueryData(["myOrganizations"], (oldData) => {
        if (!oldData) return [organizationCreated];
        return [...oldData, organizationCreated];
      });
    } catch (error) {
      NotificationService.notifyError(
        `Failed to create Organization: ${error.message}`
      );
    } finally {
      handleCancel();
    }
  };

  const createCollaborators = async (orgId) => {
    try {
      const payloads = collaborators.map((collaborator) => ({
        role: collaborator.role,
        assigned_to: collaborator.email,
        entity_id: orgId,
      }));
      await Promise.all(payloads.map(createRole));
      NotificationService.notifySuccess("Collaborators added successfully");
    } catch (error) {
      NotificationService.notifyError(
        `Failed to add collaborators to the Organization: ${error.message}`
      );
    } finally {
      handleCancel();
    }
  };

  const handleCancel = () => {
    setOpenModal(false);
    setNewOrganization(initalOrganization);
    setErrors(initialErrors);
    setCollaborators([]);
  };

  useEffect(() => {
    setSaveDisabled(!canSaveData());
  }, [newOrganization, collaborators, isValidCollaboratorsData]);

  return (
    <Modal
      show={openModal}
      size="xl"
      onClose={handleCancel}
      className="rounded-2xl"
    >
      <Modal.Header className="text-center">
        Create New Organization
      </Modal.Header>
      <Modal.Body className="p-3 py-6">
        <div className="space-y-6">
          <div className="relative">
            <CustomInput
              id={`organization_name`}
              type="text"
              label="Name *"
              value={newOrganization.name}
              error={errors.name}
              onChange={handleInputChange(
                "name",
                "Organization name is required."
              )}
              onBlur={validateName}
            />
          </div>
          <CollaboratorsForm
            collaborators={collaborators}
            setCollaborators={setCollaborators}
            setIsValidCollaboratorsData={setIsValidCollaboratorsData}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-full flex-row justify-end space-x-3">
          <ActionButtonsGroup
            saveLabel="Create"
            saveDisabled={saveDisabled}
            onSave={createNewOrganization}
            onCancel={handleCancel}
            onSaveDisabled={validateAllInputs}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
}
