import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

import { fetchApps } from "../services";
import Loading from "./common/Loading";
import SocialAccountsTable from "./settings/SocialAccountsTable";

function Settings() {
  const { organizationskuuid } = useParams();
  const [isDeletingApp, setIsDeletingApp] = useState(false);
  
  const appsQuery = useQuery({
    queryKey: ["user-social-accounts", organizationskuuid],
    queryFn: async () => await fetchApps(organizationskuuid),
    staleTime: 10 * 1000,
  });

  return (
    <div className="flex flex-row p-5">
      {appsQuery.isLoading || isDeletingApp ? (
        <Loading />
      ) : (
        <div className="w-full">
          <SocialAccountsTable
            apps={appsQuery.data}
            organizationskuuid={organizationskuuid}
            isLoading={appsQuery.isLoading}
            setIsLoading={setIsDeletingApp}
            fetchApps={appsQuery.refetch}
          />
        </div>
      )}
    </div>
  );
}

export default Settings;
