import React from 'react';
import LandingHeader from '../components/Landing/LandingHeader'
import Footer from '../components/Landing/Footer'

const PrivacyPolicyContent = () => {

    const privacyPolicyEmail = "info@campaignsplanet.com";

    const mailtoPrivacy = 
        <a 
            href={`mailto:${privacyPolicyEmail}`} 
            className="text-blue-600 underline hover:text-blue-800"
        >
            {privacyPolicyEmail}
        </a>

    const informationProvidedItems = [
        {
            title: "Account Signup:",
            description: `When you sign up, we collect your name, contact number, email address, 
            company name, and country. Additional details such as time zone and location can be provided but are 
            not required.`,
        },
        {
            title: "Event Registrations and Form Submissions:",
            description: `We collect information when you register for events, subscribe to newsletters, 
          download materials, participate in surveys, or contact us for support.`,
        },
        {
            title: "Payment Processing:",
            description: `When making purchases, we collect your name, contact information, and partial credit card 
            details (name, address, expiry date, and last four digits). We do not store full credit card numbers. 
            With your consent, we may store encrypted payment information via our secure Payment Gateway Service 
            Providers.`,
        },
        {
            title: "Testimonials:",
            description: (
                <>
                    If you authorize us to share testimonials, your name and personal details may be included 
                    after review and approval. To update or remove a testimonial, contact us at {mailtoPrivacy}.
                </>
            ),
        },
        {
            title: "Interactions with CampaignsPlanet:",
            description: `We may record and analyze interactions, such as emails, calls, and chats with our support 
            and sales teams to enhance service quality.`,
        },
        {
            title: "Social Media Platform Integration:",
            description: `If you link social media accounts (Instagram, Facebook, LinkedIn, TikTok, Twitter, 
            YouTube, Google My Business), we use official APIs for this integration. You can revoke access anytime 
            from the Manage Brands section or through the respective platform's security settings.`,
        },
    ];

    const informationCollectedItems = [
        {
            title: "Device and Browser Information:",
            description: `We collect data such as IP addresses, browser types, language preferences, referring URLs, 
            access dates and times, operating systems, device manufacturers, and network details to improve our 
            services.`,
        },
        {
            title: "Cookies and Tracking Technologies:",
            description: `We use cookies, beacons, tags, and scripts for user identification, website navigation 
            analysis, demographic insights, email campaign effectiveness tracking, and personalized engagement.`,
        },
        {
            title: "Application Logs and Analytics:",
            description: `We collect data from our products and mobile apps, including clicks, scrolls, feature 
            usage, error logs, performance metrics, and device information to enhance user experience.`,
        },
    ];

    const informationThirdPartiesItems = [
        {
            title: "Federated Authentication Services:",
            description: `When using third-party logins like LinkedIn, Microsoft, Facebook, Google, or YouTube, 
            certain personal data such as your name and email address may be shared with us.`,
        },
        {
            title: "Referrals:",
            description: (
                <>
                    If referred by another individual, we may collect data like your name and email address. 
                    To request removal, contact {mailtoPrivacy}.
                </>
            ),
        },
        {
            title: "Reselling Partners and Service Providers:",
            description: `If you interact with our reselling partners or attend sponsored events, they may 
            share your information with us.`,
        },
        {
            title: "Social Media and Public Sources:",
            description: `Public data from social media interactions (e.g., posts, comments, profile details) 
            may be collected to improve services and user understanding, even if later deleted from the platform.`,
        },
    ];

    const informationWeHoldItems = [
        {
            title: "Right to Access:",
            description: `You have the right to access and obtain a copy of the categories of personal 
            information we hold about you, including its source, purpose, processing period, and 
            disclosure recipients.`,
        },
        {
            title: "Right to Rectification:",
            description: `You can update the information we hold about you or request corrections for any 
            inaccuracies. You may also instruct us to supplement your data if necessary.`,
        },
        {
            title: "Right to Erasure:",
            description: `You can request the deletion of your personal information under certain circumstances, 
            such as when it is no longer needed for its original purpose.`,
        },
        {
            title: "Right to Restriction of Processing:",
            description: `You may request restrictions on the use of your information in specific cases, such as 
            when you object to data use and need to verify legitimate grounds for its processing.`,
        },
        {
            title: "Right to Data Portability:",
            description: `You can transfer your information to a third party in a structured, commonly used, 
            machine-readable format where processing relies on consent or automated means.`,
        },
        {
            title: "Right to Object:",
            description: `You may object to the use of your information in specific situations, such as 
            direct marketing purposes.`,
        },
        {
            title: "Right to Complain:",
            description: `You have the right to lodge a complaint with the appropriate supervisory authority 
            regarding data collection, use, or sharing.`,
        },
    ];

    const renderLiItems = (items) =>
        items.map((item, index) => (
            <li key={index}>
                <strong>{item.title}</strong> {item.description}
            </li>
        ));

    const renderOl = (items) => (
        <ol className="list-decimal ml-6 space-y-2.5 mt-2">
            {renderLiItems(items)}
        </ol>
    );

    return (
        <div className="p-8 font-sans text-gray-800 bg-white">
            <h1 className="text-4xl font-bold text-center mb-8 text-black">Privacy Policy</h1>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Information CampaignsPlanet Collects and Controls</h2>
                <p className="mt-2">
                    We collect only the information necessary to provide and improve our services. Some data is actively
                    provided by you when signing up for an account, while other information is automatically gathered when
                    you visit our website. We do not store credit card numbers.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Information Collected</h2>
                <p className="mt-2">
                    CampaignsPlanet collects information about you for legitimate purposes, obtained through direct
                    submission, automatic collection, or third-party sources.
                </p>
                <h3 className="text-lg font-medium mt-4">Information You Provide</h3>
                {renderOl(informationProvidedItems)}
                <h3 className="text-lg font-medium mt-4">Information Collected Automatically</h3>
                {renderOl(informationCollectedItems)}
                <h3 className="text-lg font-medium mt-4">Information from Third Parties</h3>
                {renderOl(informationThirdPartiesItems)}
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">California Residents</h2>
                <p className="mt-2">
                    If you are a California resident, please refer to our Privacy Notice for California Residents
                    for details regarding your rights under the California Consumer Privacy Act (CCPA).
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Purposes for Using Information</h2>
                <p className="mt-2">
                    In addition to the purposes mentioned above, we may use your information for the following purposes:
                </p>
                <ul className="list-disc ml-6 space-y-2.5">
                    <li>
                        To communicate with you (such as through email) about products you have downloaded and
                        services you have signed up for, changes to this Privacy Policy, changes to the Terms of
                        Service, or important notices.
                    </li>
                    <li>
                        To keep you informed about new products, services, events, offers, promotions, and
                        other information that may interest you.
                    </li>
                    <li>
                        To invite you to participate in surveys or solicit feedback on our products and services.
                    </li>
                    <li>
                        To set up and maintain your account and provide our services, including enabling
                        collaboration and backing up and restoring your data.
                    </li>
                    <li>
                        To understand how users interact with our products and services, monitor and prevent
                        issues, and improve our offerings.
                    </li>
                    <li>
                        To provide customer support and analyze our interactions with customers for better service.
                    </li>
                    <li>
                        To detect and prevent fraudulent transactions and illegal activities, report spam, and
                        protect the rights and interests of CampaignsPlanet, its users, third parties, and the public.
                    </li>
                    <li>
                        To update, expand, and analyze our records, identify new customers, and offer products and
                        services of interest.
                    </li>
                    <li>
                        To analyze trends, administer our websites, and track visitor navigation for better assistance.
                    </li>
                    <li>
                        To monitor and enhance the performance of our in-house AI models.
                    </li>
                    <li>
                        To monitor and improve marketing campaigns and suggest relevant content to users.
                    </li>
                </ul>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Legal Bases for Collecting and Using Information</h2>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Legal Processing Bases Applicable to CampaignsPlanet</h2>
                <p className="mt-2">
                    If you are an individual from the European Economic Area (EEA), our legal basis for information
                    collection and use depends on the personal data involved and the context of collection. Most of
                    our data collection and processing activities are based on:
                </p>
                <ul className="list-disc ml-6 space-y-2.5">
                    <li>Contractual necessity;</li>
                    <li>One or more legitimate interests of CampaignsPlanet or a third party not overridden
                        by your data protection interests;
                    </li>
                    <li>Your consent.</li>
                </ul>
                <p className="mt-2">
                    Sometimes, we may be legally required to collect your information or need it to protect vital
                    interests of you or another person.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Withdrawal of Consent</h2>
                <p className="mt-2">
                    When we rely on your consent as the legal basis, you have the right to withdraw your consent
                    at any time. However, this will not affect processing already carried out.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Legitimate Interests Notice</h2>
                <p className="mt-2">
                    When relying on legitimate interests not specified above, we will clearly explain
                    them at the time of data collection.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Your Choice in Information Use</h2>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Opt Out of Non-Essential Communications</h2>
                <p className="mt-2">
                    You may opt out of receiving newsletters and other non-essential messages by using
                    the 'unsubscribe' option included in such messages. However, you will continue
                    to receive important notices and essential transactional emails.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Disable Cookies</h2>
                <p className="mt-2">
                    You can disable cookies in your browser settings before visiting our websites.
                    However, some features may not function properly.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Optional Information</h2>
                <p className="mt-2">
                    You may choose not to provide optional profile details such as your timezone and location.
                    You can also update or delete optional information anytime and skip non-mandatory fields in
                    forms linked to our websites.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Who We Share Your Information With</h2>
                <p className="mt-2">
                    We do not sell any personal information. We share your information only as described in this Privacy Policy
                    and only with parties who adopt appropriate confidentiality and security measures.
                </p>
                <div className='mt-3'>
                    <strong>Employees and Independent Contractors: </strong>
                    Employees and independent contractors of all CampaignsPlanet group entities have access to the information
                    covered in Part I on a need-to-know basis. All employees and independent contractors are required to follow
                    this Privacy Policy for any personal information shared with them.
                </div>

                <div className='mt-3'>
                    <strong>Third-Party Service Providers: </strong>
                    We may share your personal information and aggregated or de-identified information with third-party service
                    providers we engage, such as marketing partners, event organizers, web analytics providers, and payment processors.
                    These service providers are authorized to use your personal information only as necessary to provide these services
                    to us. We do not share your information with any third-party creative generation AI models or tools.
                </div>

                <div className='mt-3'>
                    <strong>Reselling Partners: </strong>
                    We may share your personal information with authorized reselling partners in your region solely for contacting you
                    about products you have downloaded or services you have signed up for. You will have the option to opt out of
                    continuing engagement with that partner.
                </div>

                <div className='mt-3'>
                    <strong>Other Cases: </strong>
                    Other scenarios in which we may share information covered under Parts I and II are described in Part III.
                </div>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Your Rights Regarding Information We Hold</h2>
                <p className="mt-2">
                    If you are in the European Economic Area (EEA), you have the following rights concerning
                    the information CampaignsPlanet holds about you. We aim to provide the same rights no matter
                    where you reside:
                </p>
                {renderOl(informationWeHoldItems)}
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Retention of Information</h2>
                <p className="mt-2">
                    We retain your personal information as long as necessary for the purposes stated in this Privacy Policy.
                    In some cases, we may retain your information longer if required by law, such as for legal claims, compliance,
                    tax, or accounting purposes. When no longer needed, we will delete or anonymize your data from active databases.
                    Backup data will be securely stored and isolated until deletion is possible.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">What We Do With Your Information</h2>
                <p className="mt-2">
                    We use your information to provide the services you've requested, create and maintain your accounts, and monitor
                    for unauthorized activity. We also use it to communicate with you about the products you use, your customer
                    support requests, new offerings, feedback opportunities, and policy updates. We analyze the data collected to
                    better understand user needs and improve our services.
                    <br /><br />
                    We're required to have a legal basis for collecting and processing your information. In most cases, we either
                    have your consent or need the information to provide requested services. Otherwise, we must demonstrate another
                    legal basis, such as legitimate business interests.
                    <br /><br />
                    You can decline certain uses of information by not providing it or opting out later. Disabling cookies can
                    prevent some data collection, but may affect website functionality.
                    <br /><br />
                    We limit access to your personal information to employees and contractors with a legitimate need. If we share
                    your information with other parties (e.g., service providers, domain registrars, reselling partners), they must
                    use appropriate security measures and a valid reason for use.
                    <br /><br />
                    The European Economic Area (EEA) grants data rights, including access, rectification, erasure, restriction of
                    processing, data portability, objection, and complaint filing. CampaignsPlanet provides these rights regardless
                    of location.
                    <br /><br />
                    We retain your personal information only as long as needed for the purposes outlined. Once no longer required,
                    we delete, anonymize, or isolate your data.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Part II – Information that CampaignsPlanet processes on your behalf</h2>
                <p className="mt-2">
                    If you handle others' data using CampaignsPlanet services, such as customer or employee data,
                    you entrust that data to us for processing. This data is called service data.
                    <br /><br />
                    You own your service data. We protect it, limit access, and process it only per your instructions.
                    You can access, share, export, or delete your service data.
                    <br /><br />
                    We hold data in your account as long as you use CampaignsPlanet Services. After account
                    termination, data is deleted from our active database within 6 months and from
                    backups within 3 months after that.
                    <br /><br />
                    If you are in the EEA and believe your data has been entrusted to us for processing, you can
                    request data rights through the person or company that provided your data.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Information entrusted to CampaignsPlanet and purpose</h2>
                <p className="mt-2">
                    Information provided for services: You may entrust data you control to CampaignsPlanet
                    when using services or requesting technical support. This may include customer or employee
                    data stored on our servers or shared with us during support requests. All entrusted data
                    is termed "service data."
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Ownership and control of your service data</h2>
                <p className="mt-2">
                    You own your service data and have full control over it, including the ability to access,
                    share through third-party integrations, export, or delete your data.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">How we use service data</h2>
                <p className="mt-2">
                    We process service data based on your instructions, such as generating invoices
                    or running marketing campaigns using provided contact details.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Push notifications</h2>
                <p className="mt-2">
                    We process service data based on your instructions, such as generating invoices
                    or running marketing campaigns using provided contact details.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Who we share service data with</h2>
                <p className="mt-2">

                    CampaignsPlanet group and third-party sub-processors: To provide our services and technical
                    support effectively, CampaignsPlanet may engage other entities within our group and
                    third-party service providers.
                    <br /><br />
                    Employees and independent contractors: We may grant access to your service data to our
                    employees and independent contractors involved in providing our services. This access is
                    limited to specific purposes such as (i) identifying, analyzing, and resolving technical
                    issues, (ii) improving spam detection by manually reviewing reported emails, or
                    (iii) manually verifying scanned images for the accuracy of optical character recognition.
                    Access is restricted, logged, and audited. Employees and contractors also have access to
                    data you share with us for technical support or data import purposes. Our privacy and
                    security guidelines are communicated clearly to all personnel, and we enforce strict
                    privacy safeguards within the CampaignsPlanet group.
                    <br /><br />
                    Third-party integrations you enable: Our services support integrations with third-party tools
                    and platforms. If you choose to activate such integrations, you may be permitting these third
                    parties to access both your service and personal data. We recommend reviewing their
                    privacy policies before enabling integrations.
                    <br /><br />
                    Other cases: Additional scenarios in which we may share data, consistent with the principles
                    outlined in other parts of our Privacy Policy, are detailed in Part III.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Retention of Information</h2>
                <p className="mt-2">
                    We retain data in your account for as long as you actively use CampaignsPlanet services.
                    Once you terminate your account, the data will be removed from our active databases during
                    the next scheduled data clean-up, which occurs every six months.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Data Subject Requests</h2>
                <p className="mt-2">
                    If you are a resident of the European Economic Area (EEA) and believe that your data
                    is being processed by CampaignsPlanet on behalf of one of our clients, you should contact
                    the client directly for requests regarding access, rectification, erasure, restriction,
                    objection to processing, or data portability. CampaignsPlanet will assist our clients in
                    responding to such requests in a timely manner.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Part III – General</h2>
                <p className="mt-2">
                    There are some limitations to the privacy we can promise you. We will disclose personal
                    information if it's necessary to comply with a legal obligation, prevent fraud, enforce an
                    agreement, or protect our users' safety. We do not currently honor Do Not Track signals
                    from internet browsers; when a universal standard for processing them emerges, we will
                    follow it. Third-party websites and social media widgets have their own separate
                    privacy policies. Always check the relevant privacy policy before sharing personal
                    information with third parties. You can always contact us to: ask questions about our
                    privacy practices, request a GDPR-compliant Data Processing Addendum, alert us if you
                    believe we have collected personal data from a minor, or ask to have your personal
                    information removed from our blogs or forums. We will contact you to let you know if
                    we make any major changes to our privacy policy, or in the highly unlikely event that
                    we ever decide to sell our business.
                </p>
            </section>


            <section className="mb-6">
                <h2 className="text-xl font-semibold">Children’s Personal Information</h2>
                <p className="mt-2">
                    Our products and services are not directed to individuals under 16. CampaignsPlanet does
                    not knowingly collect personal information from children who are under 16 years of age.
                    If we become aware that a child under 16 has provided us with personal information, we
                    will take steps to delete such information. If you believe that a child under 16 years
                    has provided personal information to us, please write to {mailtoPrivacy} with the details, 
                    and we will take the necessary steps to delete the information we hold about that child.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">How secure is your information</h2>
                <p className="mt-2">
                    At CampaignsPlanet, we take data security very seriously. We have taken steps to implement
                    appropriate administrative, technical & physical safeguards to prevent unauthorized access,
                    use, modification, disclosure or destruction of the information you entrust to us.
                    If you have any concerns regarding the security of your data, we encourage you to write
                    to us at {mailtoPrivacy} with any questions.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Data Protection Officer</h2>
                <p className="mt-2">
                    We have appointed a Data Protection Officer to oversee our management of your personal
                    information in accordance with this Privacy Policy. If you have any questions or concerns
                    about our privacy practices with respect to your personal information, you can reach out
                    to our Data Protection Officer by sending an email to {mailtoPrivacy}.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Locations and international transfers</h2>
                <p className="mt-2">
                    We share your personal information and service data within the CampaignsPlanet Group.
                    By accessing or using our products and services or otherwise providing personal information
                    or service data to us, you consent to the processing, transfer, and storage of your personal
                    information or Service Data within the United States of America, the European Economic
                    Area (EEA) and other countries where CampaignsPlanet operates. Such transfer is subject to a
                    group company agreement that is based on EU Commission’s Model Contractual Clauses.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Do Not Track (DNT) requests</h2>
                <p className="mt-2">
                    Some internet browsers have enabled 'Do Not Track' (DNT) features, which send out a
                    signal (called the DNT signal) to the websites that you visit indicating that you don't
                    wish to be tracked. Currently, there is no standard that governs what websites can or
                    should do when they receive these signals. For now, we do not take action in response to
                    these signals.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">External links on our websites</h2>
                <p className="mt-2">
                    Some pages of our websites may contain links to websites that are not linked to this Privacy
                    Policy. If you submit your personal information to any of these third-party sites, your
                    personal information is governed by their privacy policies. As a safety measure, we
                    recommend that you not share any personal information with these third parties unless
                    you've checked their privacy policies and assured yourself of their privacy practices.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Blogs and forums</h2>
                <p className="mt-2">
                    We offer publicly accessible blogs and forums on our websites. Please be aware that
                    any information you provide on these blogs and forums may be used to contact you with
                    unsolicited messages. We urge you to be cautious in disclosing personal information in
                    our blogs and forums. CampaignsPlanet is not responsible for the personal information
                    you elect to disclose publicly. Your posts and certain profile information may remain
                    even after you terminate your account with CampaignsPlanet. To request the removal of
                    your information from our blogs and forums, you can contact us at {mailtoPrivacy}.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Social media widgets</h2>
                <p className="mt-2">
                    Our websites include social media widgets such as Facebook "like" buttons and Twitter
                    "tweet" buttons that let you share articles and other information. These widgets may
                    collect information such as your IP address and the pages you navigate in the website,
                    and may set a cookie to enable the widgets to function properly. Your interactions with
                    these widgets are governed by the privacy policies of the companies providing them.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Disclosures in compliance with legal obligations</h2>
                <p className="mt-2">
                    We may be required by law to preserve or disclose your personal information and service
                    data to comply with any applicable law, regulation, legal process or governmental request,
                    including to meet national security requirements.
                    <br /><br />
                    Enforcement of our rights We may disclose personal information and service data to a third
                    party if we believe that such disclosure is necessary for preventing fraud, investigating
                    any suspected illegal activity, enforcing our agreements or policies, or protecting the
                    safety of our users.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Business Transfers</h2>
                <p className="mt-2">
                    We do not intend to sell our business. However, in the unlikely event that we sell our
                    business or get acquired or merged, we will ensure that the acquiring entity is legally
                    bound to honor our commitments to you. We will notify you via email or through a
                    prominent notice on our website of any change in ownership or in the uses of your
                    personal information and service data. We will also notify you about any choices you
                    may have regarding your personal information and service data.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Compliance with this Privacy Policy</h2>
                <p className="mt-2">
                    We make every effort, including periodic reviews, to ensure that personal information you
                    provide is used in conformity with this Privacy Policy. If you have any concerns about
                    our adherence to this Privacy Policy or the manner in which your personal information
                    is used, kindly write to us {mailtoPrivacy}. 
                    We'll contact you, and if required, coordinate with the appropriate regulatory authorities to 
                    effectively address your concerns.
                </p>
            </section>

            <section className="mb-6">
                <h2 className="text-xl font-semibold">Notification of changes</h2>
                <p className="mt-2">
                    We may modify the Privacy Policy at any time, upon notifying you through a service
                    announcement or by sending an email to your primary email address. If we make significant
                    changes to the Privacy Policy that affect your rights, you will be provided with at
                    least 30 days' advance notice of the changes by email to your primary email address.
                    If you think that the updated Privacy Policy affects your rights with respect to your
                    use of our products or services, you may terminate your use by sending us an email within
                    30 days. Your continued use after the effective date of changes to the Privacy Policy
                    will be deemed to be your agreement to the modified Privacy Policy. You will not receive email
                    notification of minor changes to the Privacy Policy. If you are concerned about how your personal
                    information is used, you should check back at{" "}
                    <a href="https://campaignsplanet.com/privacy/">https://campaignsplanet.com/privacy/</a> periodically.

                </p>
            </section>
        </div>
    );
};


export default function PrivacyPolicyPage() {
    return (
        <div id="privacy">
            <LandingHeader />
            <div className='px-8 py-1'>
                <PrivacyPolicyContent />
            </div>
            <Footer />
        </div>
    )
}
