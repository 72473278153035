import React from "react";
import {
  FaExclamationCircle,
  FaCheckCircle,
  FaTimesCircle,
} from "react-icons/fa";

import {
  BsArrowUpRightCircle
} from "react-icons/bs";




const statusConfig = {
  EMPTY: { label: "Empty", color: "bg-orange-200 text-orange-500", icon: <FaExclamationCircle /> },
  PROCESSING: { label: "Processing", color: "bg-gray-200 text-gray-500", icon: <BsArrowUpRightCircle /> },
  READY: { label: "Ready", color: "bg-main-blue-light text-main-blue", icon: <FaCheckCircle /> },
  FAILED: { label: "Failed", color: "bg-red-200 text-red-500", icon: <FaTimesCircle /> },
  PUBLISHED: { label: "Published", color: "bg-green-200 text-green-500", icon: <FaCheckCircle /> },
  SCHEDULED: { label: "Scheduled", color: "bg-gray-200 text-gray-500", icon: <BsArrowUpRightCircle /> },
};

const StatusBadge = ({ status }) => {
  const statusInfo = statusConfig[status] || {
    label: "Unknown",
    color: "bg-gray-200 text-gray-800",
    icon: <FaExclamationCircle />,
  };

  return (
    <span
      className={`inline-flex items-center text-xs font-bold px-2 py-[2px] rounded-full mx-1 ${statusInfo.color}`}
    >
      <span className="mr-1">{statusInfo.icon}</span>
      <span className="text-[10px]">{statusInfo.label}</span>
    </span>
  );
};

export default StatusBadge;