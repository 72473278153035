import React, { Suspense } from "react";
import { Outlet, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import { ReactComponent as Logo } from "./assets/logos/campaigns-planet-logo.svg";
import { ReactComponent as ProjectsIcon } from "./assets/icons/projects_icon.svg";
import { ReactComponent as BookIcon } from "./assets/knowledge_base.svg";
import { ReactComponent as SettingsIcon } from "./assets/settings.svg";
import RoleType from "./data/enums/roleType";
import { READABLE_SITE_NAME } from "./config/constants";
import Loading from "./components/common/Loading";
import ProjectsList from "./components/leftMenu/ProjectsList";
import UserProfile from "./components/sidebar/UserProfile";
import LocalStorageKeys from "./data/enums/localStorageKeys";
import useLocalStorage from "./hooks/LocalStorage/useLocalStorage";
import { useQuery } from "@tanstack/react-query";
import { getMyRole } from "./services";
import { extractPkSk } from "./utils/common";
import UserMenu from "./layouts/UserMenu";


function Console() {
  const navigate = useNavigate();
  const location = useLocation();
  const [orgId, setOrgId, removeStorageOrgId] = useLocalStorage(LocalStorageKeys.ORGANIZATION_ID_KEY, null);
  const { organizationskuuid: paramsOrgSkuuid } = useParams();
  const { skuuid: storageOrgSkuuid } = extractPkSk(orgId);
  const orgSkuuid = paramsOrgSkuuid || storageOrgSkuuid

  const { data: myOrgInfo } = useQuery({
    queryKey: ["MyRole", orgSkuuid],
    queryFn: () => getMyRole(orgSkuuid),
    staleTime: 5 * 60 * 1000,
    enabled: !!orgSkuuid,
  });
  const sideBarNavLinkStyles = ({ isActive }, pathName) => {
    const isResourcePath =
      /^\/mkt\/(?:organizations\/[\w-]+\/)?resources\//.test(location.pathname);
    const _isActive =
      pathName && pathName.startsWith("/mkt/resources") && isResourcePath
        ? true
        : isActive;

    return _isActive
      ? "self-stretch h-12 px-4 bg-indigo-50 text-main-blue rounded-lg justify-start items-center gap-6 inline-flex"
      : "self-stretch h-12 px-4 justify-start items-center gap-6 inline-flex";
  };

  return (
    <>
      <UserMenu orgId={orgId} />
      <div className="relative w-screen h-screen flex flex-row justify-start items-center font-['Roboto'] overflow-hidden">
        <div className="max-lg:hidden w-80 self-stretch px-3 pt-6 pb-2 bg-white shadow shadow-very-light-gray-gray flex-col items-center gap-8 inline-flex z-10">
          <div
            onClick={() => navigate("../")}
            className="self-stretch text-main-blue pl-3.5 justify-start items-center gap-3 inline-flex hover:cursor-pointer"
          >
            <Logo />
            <div className="text-black text-2xl font-semibold leading-tight">
              {READABLE_SITE_NAME}
            </div>
          </div>
          <div className="flex flex-col justify-between h-full">
            <div className="self-stretch flex-col justify-start items-center gap-1 flex">
              <NavLink
                to={`/mkt/organizations/${orgSkuuid}/projects`}
                className={sideBarNavLinkStyles}
              >
                <ProjectsIcon />
                <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                  <div className="self-stretch text-base font-normal leading-normal tracking-wide">
                    Projects
                  </div>
                </div>
              </NavLink>
              <ProjectsList organizationId={orgSkuuid} />
              <NavLink
                to={`/mkt/organizations/${orgSkuuid}/resources/knowledge-bases`}
                className={(navData) =>
                  sideBarNavLinkStyles(
                    navData,
                    "/mkt/resources/knowledge-bases"
                  )
                }
              >
                <BookIcon />
                <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                  <div className="self-stretch text-base font-normal leading-normal tracking-wide">
                    Resources
                  </div>
                </div>
              </NavLink>

              {myOrgInfo?.role !== RoleType.MEMBER && (
                <NavLink
                  to={`/mkt/organizations/${orgSkuuid}/settings/general-settings`}
                  className={(navData) =>
                    sideBarNavLinkStyles(
                      navData,
                      "/mkt/settings/general-settings"
                    )
                  }
                >
                  <SettingsIcon />
                  <div className="grow shrink basis-0 self-stretch py-3 flex-col justify-start items-start gap-0.5 inline-flex">
                    <div className="self-stretch text-base font-normal leading-normal tracking-wide">
                      Settings
                    </div>
                  </div>
                </NavLink>
              )}
            </div>
            <UserProfile
              orgId={orgId}
              setOrgId={setOrgId}
              removeStorageOrgId={removeStorageOrgId}
            />
          </div>
        </div>
        <div className="bg-clip-padding bg-very-light-gray w-full h-full px-5 py-5 max-lg:p-0 overflow-x-hidden">
          <div className="bg-white rounded-md w-full h-full">
            <Suspense fallback={<Loading />}>
              <Outlet context={{ orgId }} />
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}

export default Console;
