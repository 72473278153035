export const validateInput = (input, field, setErrors, errorMessage) => {
  if (!input.trim()) {
    const resultErrorMessage =
      errorMessage ||
      `${field.charAt(0).toUpperCase() + field.slice(1)} is required.`;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: resultErrorMessage,
    }));
    return resultErrorMessage;
  } else {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    return null;
  }
};

export const validateEmailInput = (input, field, setErrors, errorMessage) => {
  const isEmail = isValidEmail(input);
  if (!isEmail) {
    const resultErrorMessage = errorMessage || `Email is invalid.`;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [field]: resultErrorMessage,
    }));
    return resultErrorMessage;
  } else {
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));
    return null;
  }
};

export function isEmpty(obj) {
  if (obj === null || obj === undefined) {
    return true;
  }
  return Object.keys(obj).length === 0;
}

export function isValidMetric(value) {
  if (typeof value === "string" && isNaN(Number(value))) {
    throw new Error("Metrics cannot be letters");
  }

  if (!Number.isInteger(Number(value))) {
    throw new Error("Metrics cannot be a decimal number");
  }

  if (Number(value) < 0) {
    throw new Error("Metrics cannot be negative");
  }

  return true;
}

export function isValidFormat(input) {
  const regex =
    /^\[\(([\w#-]+ \d{2}\/\d{2}\/\d{4} \d{2}:\d{2})\)(, \(([\w#-]+ \d{2}\/\d{2}\/\d{4} \d{2}:\d{2})\))*\]$/;

  return regex.test(input);
}

export function isValidFutureDate(date) {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const inputDate = new Date(date);

  return inputDate >= today;
}

export const isInputValid = (name) => {
  const sqlInjectionProtectionRegex =
    /^(?!.*(SELECT|INSERT|UPDATE|DELETE|DROP|UNION|--|;|\")).*$/i;
  const alphanumericValidationRegex = /^(?=.*[A-Za-z0-9])[A-Za-z0-9._-]{3,}$/;
  return (
    sqlInjectionProtectionRegex.test(name) &&
    alphanumericValidationRegex.test(name)
  );
};

export function isValidEmail(email) {
  if (typeof email !== "string") {
    return false;
  }
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
