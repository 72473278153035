import React, { useEffect, useState, useRef } from "react";
import Step1 from "../../assets/step1.svg";
import Step2 from "../../assets/step2.svg";
import Step3 from "../../assets/step3.svg";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { validateInput } from "../../utils/validations";
import { processStringToArrayByCommaSplit } from "../../utils/common";
import {
  createKnowledgeBase,
  getUploadPresignedUrls,
  uploadFileToS3,
} from "../../services";
import * as NotificationService from "../../utils/notificationService";
import AddDataFilterButton from "./AddDataFilterButton";
import AddDataFilterTitle from "./AddDataFilterTitle";
import DataFilterForm from "./DataFilterForm";
import TitleWithProgres from "./TitleWithProgress";
import ExpandableListItem from "./ExpandableListItem";
import ActionButtonsGroup from "../common/buttons/ActionButtonsGrup";
import DataSourceType from "../../data/enums/data_source_type_enum";
import InputErrorDisplayer from "../common/forms/InputErrorDisplayer";

function KnowledgeBase() {
  const { organizationskuuid } = useParams();
  const { orgId } = useOutletContext();
  const [isReviewing, setIsReviewing] = useState(false);
  const [creatingDataFilter, setCreatingDataFilter] = useState(false);
  const [dataSourceSelected, setDataSourceSelected] =
    useState("Select Data Source");
  const [dataFiltersAdded, setDataFiltersAdded] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [filterName, setFilterName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [authors, setAuthors] = useState("");
  const [isSecondStep, setIsSecondStep] = useState(false);
  const [knowledgeBaseName, setKnowledgeBaseName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [errors, setErrors] = useState({ name: "", data_filters_added: "" });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validateDFFormInputs, setValidateDFFormInputs] = useState(false);

  const navigate = useNavigate();
  const CUSTOM_FILES = DataSourceType.toBackendType(DataSourceType.CUSTOM_FILES)
  const dataFiltersAddedLengthRef = useRef(dataFiltersAdded.length);

  const cleanDataFilterFormData = () => {
    setFilterName("");
    setKeywords("");
    setAuthors("");
    setStartDate("");
    setEndDate("");
    setDataSourceSelected("Select Data Source");
  };

  const handleDataCreated = () => {
    setDataFiltersAdded((prevDataList) => [
      ...prevDataList,
      {
        name: filterName,
        source: dataSourceSelected,
        keywords,
        authors,
        file_names: selectedFiles,
      },
    ]);
    cleanDataFilterFormData();
    setSelectedFiles([]);
    setCreatingDataFilter(false);
  };

  useEffect(() => {
    if (dataFiltersAddedLengthRef.current !== dataFiltersAdded.length) {
      validateAllInputsSecondStep();
    }
    dataFiltersAddedLengthRef.current = dataFiltersAdded.length;
  }, [dataFiltersAdded]);

  const handleCancelNewDataFilter = () => {
    cleanDataFilterFormData();
    setCreatingDataFilter(false);
  };

  const handleSelect = (item) => {
    setDataSourceSelected(item);
  };

  const handleToggleExpand = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  const handleCreateNew = () => {
    setIsSecondStep(true);
  };

  const handleSave = async () => {
    const newKbCreated = await createKnowledgeBaseHandler();
    if (newKbCreated) {
      navigate(
        `/mkt/organizations/${organizationskuuid}/resources/knowledge-bases`,
        {
          state: { newKbCreated },
        }
      );
    }
  };

  const handleCancelThirdStep = () => {
    setIsReviewing(false);
    setIsSecondStep(true);
  };

  const handleCancelSecondStep = () => {
    setIsSecondStep(false);
  };

  const handleContinueSecondStep = () => {
    setIsReviewing(true);
  };

  const getFileNames = (files) => {
    const fileNames = files.map((file) => file.name);
    return fileNames;
  };

  const uploadFilesToS3 = async (files) => {
    if (files.length === 0) return null;

    const fileNames = getFileNames(files);
    const payload = {
      file_names: fileNames,
      organization_skuuid: organizationskuuid,
    };

    try {
      const { presigned_urls, id } = await getUploadPresignedUrls(payload);

      Promise.all(
        files.map(
          async (file, index) =>
            await uploadFileToS3(file, presigned_urls[index])
        )
      );

      return id;
    } catch (error) {
      throw error;
    }
  };

  const createKnowledgeBaseHandler = async () => {
    try {
      let kbPreId = null;

      const customDataFilters = dataFiltersAdded.filter((df) => df.source === CUSTOM_FILES);
      for (const dataFilter of customDataFilters) {
        const response = await uploadFilesToS3(dataFilter.file_names);
        if(!kbPreId) kbPreId = response;
      }

      const dataFilters = dataFiltersAdded.map((dataFilter) => ({
        ...dataFilter,
        keywords: processStringToArrayByCommaSplit(dataFilter.keywords),
        authors: processStringToArrayByCommaSplit(dataFilter.authors),
        source: dataFilter.source,
        file_names: getFileNames(dataFilter.file_names),
      }));

      const payload = {
        title: knowledgeBaseName,
        knowledge_base_data_filters: dataFilters ?? [],
        organization_id: orgId,
        ...(kbPreId ? { id: `KB#${kbPreId}` } : {}),
      };

      const newKbCreated = await createKnowledgeBase(payload);
      NotificationService.notifySuccess("Knowledge base created successfully");
      return newKbCreated;
    } catch (error) {
      const errorMessage = error.message || "An unexpected error occurred";
      NotificationService.notifyError(
        `Failed to create Knowledge base: ${errorMessage}`
      );
      return null;
    }
  };

  const handleDelete = (index) => {
    const newDataList = dataFiltersAdded.filter((_, i) => i !== index);
    setDataFiltersAdded(newDataList);
  };

  const handleInputChange = (setter, field, errorMessage) => (e) => {
    const value = e.target.value;
    setter(value);
    validateInput(value, field, setErrors, errorMessage);
  };

  const handleFilesChange = (files) => {
    setSelectedFiles(files);
  };

  const validateKbName = () => {
    validateInput(
      knowledgeBaseName,
      "name",
      setErrors,
      "Knowledge Base name is required."
    );
  };

  const validateAllInputsFirstStep = () => {
    validateKbName();
  };

  const validateAllInputsSecondStep = () => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      data_filters_added:
        dataFiltersAdded.length === 0
          ? "At least one data filter is required"
          : "",
    }));
  };

  return (
    <>
      <div className="flex flex-row p-4 items-center justify-between w-full max-md:flex-col max-md:items-start">
        <h1 className="text-2xl font-bold">Create Knowledge Base</h1>
        <div className="flex flex-1 text-xl text-center text-sky-500 rounded-none max-md:max-w-full"></div>
      </div>
      {creatingDataFilter ? (
        <div className="flex flex-col bg-white h-[calc(120vh-270px)]  max-lg:h-[calc(100vh-175px)]">
          <div className="flex flex-col flex-1 items-center w-full max-md:max-w-full overflow-auto">
            <div className="flex flex-col flex-1 px-5 py-5 max-w-full border border-solid border-zinc-200 w-[896px]">
              <TitleWithProgres imgSrc={Step2} altText="Filter" />
              <div className="flex flex-col flex-1 mt-8 w-full max-md:max-w-full">
                <div className="flex flex-col w-full max-md:max-w-full">
                  <div className="flex flex-col pt-6 pb-12 w-full bg-white min-h-[276px] max-md:max-w-full">
                    <div className="flex justify-between items-center w-full max-md:max-w-full">
                      <AddDataFilterTitle />
                    </div>
                    <DataFilterForm
                      item={{
                        keywords,
                        authors,
                        name: filterName,
                        source: dataSourceSelected,
                        start_date: startDate,
                        end_date: endDate,
                      }}
                      handleSelect={handleSelect}
                      onChangeName={setFilterName}
                      setKeywords={setKeywords}
                      setAuthors={setAuthors}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      selectedFiles={selectedFiles}
                      handleFilesChange={handleFilesChange}
                      currentDataFilters={dataFiltersAdded}
                      validateInputs={validateDFFormInputs}
                      setValidateInputs={setValidateDFFormInputs}
                    />
                  </div>
                </div>
                <div className="flex justify-center items-center mt-6">
                  <ActionButtonsGroup
                    saveLabel="Save"
                    onCancel={handleCancelNewDataFilter}
                    onSave={handleDataCreated}
                    saveDisabled={
                      filterName.trim().length === 0 ||
                      DataSourceType.getTypeFromString(dataSourceSelected) ===
                        DataSourceType.NO_SELECTED ||
                      (DataSourceType.getTypeFromString(dataSourceSelected) !==
                        DataSourceType.CUSTOM_FILES &&
                        keywords.length === 0 &&
                        authors.length === 0) ||
                      (DataSourceType.getTypeFromString(dataSourceSelected) ===
                        DataSourceType.CUSTOM_FILES &&
                        selectedFiles.length === 0)
                    }
                    onSaveDisabled={() => {
                      setValidateDFFormInputs(true);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : isReviewing ? (
        <div className="flex flex-col h-[calc(125vh-270px)]  max-lg:h-[calc(100vh-175px)] items-center   justify-center">
          <div className="flex overflow-auto flex-col px-5 pt-5 max-w-4xl border border-solid border-zinc-200">
            <TitleWithProgres imgSrc={Step3} altText="Filter" />
            <div className="flex overflow-auto flex-col mt-8 w-full max-md:max-w-full">
              <div className="flex flex-col w-full max-md:max-w-full">
                <div className="flex overflow-auto flex-col py-5 w-full bg-white max-md:max-w-full">
                  <div className="flex justify-between text-center items-center w-full max-md:max-w-full">
                    <div className="flex flex-col flex-1 justify-center items-center w-full max-w-full">
                      <div className="text-2xl tracking-wide leading-none text-neutral-900">
                        Review Knowledge Base
                      </div>
                      <div className="mt-2 text-base leading-6 text-neutral-900 text-opacity-80 max-md:max-w-full">
                        Make sure your knowledge base looks just the way you
                        want it to, and click Save. You'll also be able to edit
                        this knowledge base later on
                      </div>
                    </div>
                  </div>
                  <div className="w-full h-full mt-5 border-none bg-white text-xl tracking-wide leading-none">
                    Knowledge Base Name
                  </div>
                  <div className="flex flex-col px-5 justify-center mt-4 w-full text-xl tracking-wide leading-none text-neutral-900 max-md:max-w-full">
                    <div className="self-stretch px-3 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[40px] max-md:max-w-full">
                      <input
                        type="text"
                        className="w-full h-full border-none bg-white text-xl tracking-wide leading-none"
                        placeholder="Enter Knowledge Base name *"
                        value={knowledgeBaseName}
                        onBlur={validateKbName}
                        onChange={handleInputChange(
                          setKnowledgeBaseName,
                          "name",
                          "Knowledge Base name is required."
                        )}
                      />
                    </div>
                    <InputErrorDisplayer message={errors.name} />
                  </div>
                  <hr className="my-5" />
                  {dataFiltersAdded.length !== 0 && (
                    <>
                      <div className="w-full h-full mt-4 border-none bg-white text-xl tracking-wide leading-none">
                        Data Filters
                      </div>
                      <div className="flex flex-col w-full max-md:max-w-full mt-6 space-y-4">
                        {dataFiltersAdded.map((item, index) => (
                          <div key={index} className="border-b border-solid">
                            <ExpandableListItem
                              index={index}
                              title={item.name}
                              isExpanded={expandedIndex === index}
                              handleToggleExpand={handleToggleExpand}
                            />
                            {expandedIndex === index && (
                              <DataFilterForm
                                item={item}
                                handleSelect={handleSelect}
                                selectedFiles={selectedFiles}
                                readOnly
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <div className="flex justify-center items-center mt-6">
                    <ActionButtonsGroup
                      cancelLabel="Back"
                      onCancel={handleCancelThirdStep}
                      onSave={handleSave}
                      onSaveDisabled={validateAllInputsFirstStep}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : !isSecondStep ? (
        <>
          <div className="flex flex-col bg-white">
            <div className="flex flex-col flex-1 items-center w-full max-md:max-w-full justify-center">
              <div className="flex flex-col flex-1 px-5 py-5 max-w-full border border-solid border-zinc-200 w-[896px]">
                <TitleWithProgres imgSrc={Step1} altText="Name" />
                <div className="flex flex-col flex-1 mt-8 w-full max-md:max-w-full justify-center">
                  <div className="flex flex-col w-full max-md:max-w-full">
                    <div className="flex flex-col pt-6 pb-12 w-full bg-white max-md:max-w-full">
                      <div className="flex w-full text-center max-md:max-w-full">
                        <div className="flex flex-col flex-1 shrink self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
                          <div className="text-2xl tracking-wide leading-none text-neutral-900 max-md:max-w-full">
                            Name your Knowledge Base
                          </div>
                          <div className="mt-2 text-base leading-6 text-neutral-900 text-opacity-80 max-md:max-w-full">
                            Start by crafting a clear and concise name that
                            reflects the content you'll be storing. <br />
                            This could be a project name, a topic area, or
                            simply a descriptive title – the choice is yours!
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col justify-center mt-6 w-full text-xl tracking-wide leading-none text-neutral-900 max-md:max-w-full">
                        <div className="flex-1 shrink self-stretch px-5 py-4 w-full bg-white rounded-lg border border-solid border-slate-300 min-h-[60px]">
                          <input
                            type="text"
                            className="w-full h-full border-none bg-white text-xl tracking-wide leading-none"
                            placeholder="Enter Knowledge Base name *"
                            value={knowledgeBaseName}
                            onBlur={validateKbName}
                            onChange={handleInputChange(
                              setKnowledgeBaseName,
                              "name",
                              "Knowledge Base name is required."
                            )}
                          />
                        </div>
                        <InputErrorDisplayer message={errors.name} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center items-center">
                  <ActionButtonsGroup
                    saveLabel="Continue"
                    onSave={handleCreateNew}
                    saveDisabled={knowledgeBaseName.trim().length === 0}
                    onSaveDisabled={validateAllInputsFirstStep}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col bg-white  h-[calc(125vh-270px)]  max-lg:h-[calc(100vh-175px)]">
            <div className="flex flex-col overflow-auto flex-1 items-center">
              <div className="flex flex-col flex-1 px-5 py-5 max-w-full border border-solid border-zinc-200 w-[896px]">
                <TitleWithProgres imgSrc={Step2} altText="Filter" />
                <div className="flex flex-col flex-1 mt-8">
                  <div className="flex flex-col">
                    <div className="flex flex-col justify-between w-full bg-white min-h-[276px] max-md:max-w-full">
                      <div className="flex justify-between items-center">
                        <AddDataFilterTitle />
                      </div>
                      <AddDataFilterButton
                        onClick={() => {
                          setCreatingDataFilter(true);
                        }}
                      />
                      <InputErrorDisplayer
                        message={errors.data_filters_added}
                        className="text-center"
                      />
                      <div className="flex flex-col w-full max-md:max-w-full mt-6 space-y-4">
                        {dataFiltersAdded.map((item, index) => (
                          <div
                            key={index}
                            className="border-b border-gray-300 rounded-b-lg"
                          >
                            <ExpandableListItem
                              index={index}
                              title={item.name}
                              isExpanded={expandedIndex === index}
                              handleToggleExpand={handleToggleExpand}
                              handleDelete={handleDelete}
                            />
                            {expandedIndex === index && (
                              <DataFilterForm
                                item={item}
                                handleSelect={handleSelect}
                                selectedFiles={selectedFiles}
                                readOnly
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex justify-center items-center mt-6">
                      <ActionButtonsGroup
                        cancelLabel="Back"
                        saveLabel="Continue"
                        onCancel={handleCancelSecondStep}
                        onSave={handleContinueSecondStep}
                        saveDisabled={dataFiltersAdded.length === 0}
                        onSaveDisabled={validateAllInputsSecondStep}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default KnowledgeBase;
