import React from 'react'
import InputErrorDisplayer from './InputErrorDisplayer'

export default function CustomInput({
    id,
    type,
    label,
    value,
    error,
    placeholder = "",
    onChange = () => { },
    onBlur = () => { },
}) {
    return (
        <>
            <input
                id={id}
                type={type}
                className={
                    `relative block w-full h-[35px] px-2 pb-2 pt-4 
                    text-sm text-gray-900 bg-transparent rounded-lg border-1 
                    appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer 
                    ${error ? "border-red-500" : "border-gray-300"}`
                }
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
            />
            <label
                htmlFor={id}
                className={
                    `absolute text-sm text-gray-500 duration-300 transform -translate-y-4 
                    scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 
                    peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 
                    peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 
                    peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 
                    rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`
                }
            >
                {label}
            </label>
            <InputErrorDisplayer message={error} />
        </>
    )
}
