import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../AuthProvider";
import { ReactComponent as ArrowDropDownIcon } from "../../assets/arrow_drop_down.svg";
import { ReactComponent as PlusIcon } from "../../assets/plus-square.svg";
import { getMyOrganizations } from "../../services";
import { getInitials, extractPkSk } from "../../utils/common";
import CreateOrganizationModal from "../organizations/CreateOrganizationModal";

function UserProfile({ orgId, setOrgId, removeStorageOrgId }) {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const ref = useRef();

  const [showDropDown, setShowDropDown] = useState(false);

  const [openCreateOrgModal, setOpenCreateOrgModal] = useState(false);
  const user = `${auth.user.given_name}.${auth.user.family_name}`;

  const myOrganizationsQuery = useQuery({
    queryKey: ["myOrganizations"],
    queryFn: () => getMyOrganizations(user),
    staleTime: 60 * 1000,
  });

  const togglerDropDown = () => {
    setShowDropDown(!showDropDown);
  };

  let orgSelected = myOrganizationsQuery.data?.find((org) => org.id === orgId);

  const handleOrganizationSelect = (organization) => {
    const { skuuid } = extractPkSk(organization.id);
    setOrgId(organization.id);
    const newPath = location.pathname.replace(
      /organizations\/[^/]+/,
      `organizations/${skuuid}`
    );
    navigate(newPath);
    togglerDropDown();
  };

  const onClickCreateNewOrg = () => {
    setOpenCreateOrgModal(true);
    togglerDropDown();
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!ref?.current?.contains(event.target)) {
        setShowDropDown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [ref]);

  return (
    <div>
      <button
        type="button"
        onClick={togglerDropDown}
        className="self-stretch p-2 bg-white rounded-[5px] shadow justify-start items-center gap-[15px] inline-flex w-[230px]"
      >
        <div className="w-9 h-9 p-2.5 bg-main-blue rounded-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
          <div className="text-center text-white text-base font-normal leading-normal tracking-wide">
            {getInitials(orgSelected?.name)}
          </div>
        </div>
        <div className="grow shrink basis-0 text-base font-normal leading-normal tracking-wide">
          {orgSelected?.name}
        </div>
        <ArrowDropDownIcon />
      </button>
      {showDropDown && (
        <div
          className="z-10 absolute bottom-20 w-[230px] bg-white divide-y divide-gray-100 rounded-[5px] shadow border flex flex-col"
          ref={ref}
        >
          <ul
            className={`py-2 text-base font-normal max-h-52 overflow-y-auto flex-1`}
            aria-labelledby="dropdownTopButton"
          >
            {myOrganizationsQuery.data?.map((organization) => (
              <li
                key={organization.id}
                className="flex items-center gap-3 px-4 py-2 hover:bg-gray-100 cursor-pointer rounded-md my-2 mx-1 bg-white border-b-2"
                onClick={() => handleOrganizationSelect(organization)}
              >
                <div className="w-9 h-9 p-2.5 bg-main-blue rounded-[50px] flex-col justify-center items-center gap-2.5 inline-flex">
                  <div className="text-center text-white text-base font-normal leading-normal tracking-wide">
                    {getInitials(organization?.name)}
                  </div>
                </div>
                <div className="grow shrink basis-0 text-base font-normal leading-normal tracking-wide">
                  {organization?.name}
                </div>
              </li>
            ))}
          </ul>
          <hr className="bg-gray-200" />

          <a
            href="#"
            onClick={onClickCreateNewOrg}
            className="px-4 py-2 text-base font-normal text-center hover:bg-gray-100 rounded-md hover:text-black flex items-center text-black"
          >
            <PlusIcon className="w-3 h-3" />{" "}
            <span className="ml-1 font-semibold">Create new Organization</span>
          </a>
        </div>
      )}
      <CreateOrganizationModal
        openModal={openCreateOrgModal}
        setOpenModal={setOpenCreateOrgModal}
      />
    </div>
  );
}

export default UserProfile;
