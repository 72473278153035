import React, { useEffect, useState } from "react";
import { Modal } from "flowbite-react";

import { getTomorrowDate, formatDateTimeUS } from "../utils/common";
import ActionButtonsGroup from "./common/buttons/ActionButtonsGrup";


export default function SchedulePostModal({
    openModal,
    scheduleTargetPlatformData,   
    handleDateTimeChange,
    schedulePostHandler,
    handleCancel,
    currentTargetPlatform,
}) {
    const [isScheduled, setIsScheduled] = useState(false);

    useEffect(() => {
        if (currentTargetPlatform && currentTargetPlatform.status === "SCHEDULED") setIsScheduled(true);
        else setIsScheduled(false);
    }, [currentTargetPlatform]);

    return (
        <Modal
            show={openModal}
            size="2xl"
            onClose={() => handleCancel()}
        >
            <Modal.Header className="items-center">
                Schedule Post
                {isScheduled && (
                    <div className="mt-2 text-sm text-gray-500">
                        This post is scheduled for {formatDateTimeUS(currentTargetPlatform && currentTargetPlatform.schedule_time)}
                    </div>
                )}
            </Modal.Header>
            <Modal.Body>
                <div className="space-y-6">
                    <div className="relative">
                        <input
                            type="date"
                            value={scheduleTargetPlatformData.start_date}
                            onChange={handleDateTimeChange}
                            min={getTomorrowDate()}
                            id="campaign_start_date"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                            placeholder=" "
                        />
                        <label
                            htmlFor="campaign_start_date"
                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                            Start Date
                        </label>
                    </div>
                    <div className="relative">
                        <input
                            type="time"
                            value={scheduleTargetPlatformData.start_time}
                            onChange={handleDateTimeChange}
                            id="campaign_start_time"
                            className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-main-blue peer"
                            placeholder=" "
                        />
                        <label
                            htmlFor="campaign_start_date"
                            className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white px-2 peer-focus:px-2 peer-focus:text-maborder-main-blue peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                        >
                            Start Time
                        </label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <ActionButtonsGroup
                    onSave={() => schedulePostHandler()}
                    onCancel={() => handleCancel()}
                    saveLabel="Schedule Post"
                />
            </Modal.Footer>
        </Modal>
    )
}
