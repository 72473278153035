import React from "react";
import { READABLE_SITE_NAME, SITE_EMAIL } from "../../config/constants";
import campaignsPlanetLogo from "../../assets/logos/campaigns-planet-logo.svg";
import sentellentLogo from "../../assets/logos/sentellent_logo.svg";

const Footer = () => {
  const poweredByUrl = "https://sentellent.com/#contact-us-component";

  return (
    <div className="py-12 px-10 bg-black flex justify-between items-start max-md:flex-col max-md:px-5 max-md:space-y-3">
      <div className="flex flex-col w-1/3 max-md:w-full">
        <div className="flex items-center gap-3 text-2xl font-medium leading-5 text-white">
          <img
            src={campaignsPlanetLogo}
            alt="Campaigns Planet Logo"
            className="h-8"
          />
          <span className="text-lg font-bold text-gradient bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-orange-500">
            {READABLE_SITE_NAME.toUpperCase()}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-center w-1/3 max-md:w-full">
        <div className="text-xl font-bold tracking-normal text-white">
          Get in touch
        </div>
        <div className="mt-7 text-base tracking-normal leading-7 text-white text-center">
          <span className="leading-5 font-[450]">San Jose, United States</span>
          <br />
          <span className="font-[450]">
            <a
              href={`mailto:${SITE_EMAIL}`}
              className="text-white underline hover:underline hover:text-white hover:font-bold"
            >
              {SITE_EMAIL}
            </a>
          </span>
        </div>
        <a
          className="mt-7 text-center justify-items-center"
          href={poweredByUrl}
        >
          <div className="flex items-center gap-3 text-white">
            <img
              src={sentellentLogo}
              alt="Sentellent Logo"
              className="h-[60px]"
            />
            <div>
              <div className="text-lg font-medium">
                Powered by:
              </div>
              <span className="text-xl font-bold">
                SENTELLENT
              </span>
            </div>
          </div>
        </a>
      </div>
      <div className="flex justify-end max-md:justify-center items-center w-1/3 max-md:w-full">
        <a
          href={`${window.location.origin}/privacy`}
          className="text-sm text-white underline hover:text-gray-300"
        >
          Privacy
        </a>
      </div>
    </div>
  );
};

export default Footer;
