import CampaignType from "../data/enums/campaignType"

export const READABLE_SITE_NAME = 'Campaigns Planet'
export const SITE_EMAIL = 'info@campaignsplanet.com'

export const POLOTNO_KEY = process.env.REACT_APP_POLOTNO_KEY

export const API_SERVER =  process.env.REACT_APP_API_SERVER

export const COGNITO_REGION = process.env.REACT_APP_COGNITO_REGION
export const COGNITO_DOMAIN = process.env.REACT_APP_COGNITO_DOMAIN
export const COGNITO_USER_POOL_ID = process.env.REACT_APP_COGNITO_USER_POOL_ID
export const COGNITO_USER_POOL_APP_CLIENT_ID = process.env.REACT_APP_COGNITO_USER_POOL_APP_CLIENT_ID


export const APPS_LINKEDIN_OAUTH_URL = "https://www.linkedin.com/oauth/v2/authorization"
export const APPS_LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID

export const APPS_FACEBOOK_OAUTH_URL = "https://www.facebook.com/dialog/oauth"
export const APPS_FACEBOOK_CLIENT_ID = process.env.REACT_APP_APPS_FACEBOOK_CLIENT_ID
export const APPS_FACEBOOK_CONFIG_ID = process.env.REACT_APP_APPS_FACEBOOK_CONFIG_ID

export const APPS_INSTAGRAM_CLIENT_ID = '359878223317407'
export const APPS_INSTAGRAM_CONFIG_ID = '581976657434110'

export const APPS_X_OAUTH_URL = "https://twitter.com/i/oauth2/authorize"
export const APPS_X_CLIENT_ID = process.env.REACT_APP_APPS_X_CLIENT_ID

// Campaigns
const MANUAL = CampaignType.MANUAL;
const AUTOMATIC = CampaignType.AUTOMATIC;

export const campaignOptions = [
  { id: MANUAL, value: MANUAL, label: CampaignType.toReadable(MANUAL) },
  { id: AUTOMATIC, value: AUTOMATIC, label: CampaignType.toReadable(AUTOMATIC) },
];

export const initialExpandedSections = {
  warning: false,
  error: false,
  sucess: false,
};

export const initalCampaign = {
  title: "",
  type: CampaignType.MANUAL,
};

export const initalOrganization = {
  name: "",
};

export const defaultCollaborator = {
  name: "",
  email: "",
  role: "",
};